





























































































































import vueTypedMixins from 'vue-typed-mixins'
import MChainSearch from '@/components/mobile/m-chainSearch.vue'
import MTitle from '@/components/mobile/m-title.vue'
import MCopyBtn from '@/components/mobile/m-copyBtn.vue'
import TxStatus from '@/components/pc/txStatus.vue'
import tradeDetail from '@/mixin/tradeDetail'

export default vueTypedMixins(tradeDetail).extend({
  components: {
    MChainSearch,
    MTitle,
    MCopyBtn,
    TxStatus,
  },
})
