





import Vue from 'vue'
import copyIcon from '@/assets/images/blockChainBrowser/blockDetail/copy.png'
import Clipboard from 'clipboard'
import { message } from 'ant-design-vue'

export default Vue.extend({
  props: {
    copyTxt: [String, Number],
  },
  data() {
    return {
      copyIcon,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const clipboard = new Clipboard(this.$refs.btn as Element)
      clipboard.on('success', (e) => {
        message.success(this.$t('lang.components.copySuc') as string)
        e.clearSelection()
      })

      clipboard.on('error', () => {
        message.error(this.$t('lang.components.copyErr') as string)
      })
    },
  },
})
