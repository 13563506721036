/* eslint-disable @typescript-eslint/no-explicit-any */
import { queryTransaction } from "ycc-api/dist/cmjs/service/blockDetail/index";
import Vue from "vue";
import { yccApi } from "@/service/api";

export default Vue.extend({
  data() {
    return {
      searchValue: "",
      data: {} as any,
      loading: false,
    };
  },
  watch: {
    "$route.query.hash"() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    clickSearch(str: string) {
      console.log(str);
    },
    setSearchValue(v: string) {
      this.searchValue = v;
    },
    async init() {
      this.loading = true;

      const ret = await queryTransaction(
        "ycc",
        yccApi,
        this.$route.query.hash as string
      );
      this.loading = false;
      if (ret) {
        this.data = ret;
      }
    },
  },
});
